import { post } from '@/utils/http'
//免登录获取单条溜溜壶详情(H5用)
export const info = function (data) {
    return post({
        url: "/app/user/circle/info",
        data
    })
}

//微信跳转APP所需参数
export const shareConfig = function (data) {
  return post({
      url: "/app/user/wx/shareConfig",
      data
  })
}

//h5内再分享所需参数
export const shareMsg = function (data) {
    return post({
        url: "/app/user/circle/share",
        data
    })
  }